<script lang="ts" setup>
import LineThumbsUp from '@docue/docue-ui-v2/icons/LineThumbsUp.vue'

const { $posthog } = useNuxtApp()
const { workspace } = useAuth()
const { hasTrialSubscription } = useSubscription()
const { data: config } = useQueryConfig.current()
const { formatCurrency } = useIntl()

const rewardAmount = computed(() => config.value?.referral.reward ?? 0)
</script>

<template>
  <aside
    class="hidden w-60 flex-col border-r border-gray-200 md:flex"
  >
    <AppNavigation class="flex-1 overflow-y-auto px-2.5 py-4" />

    <div
      v-if="config?.referral.enabled"
      class="
          relative
          mx-2.5
          my-4
          flex
          items-center
          gap-2
          rounded-lg
          px-2
          py-1
          outline
          outline-0
          focus-within:bg-gray-25
          focus-within:outline-1
          hover:bg-blue-25
          "
    >
      <span class="flex size-6 items-center justify-center">
        <LineThumbsUp class="size-4 text-gray-600" />
      </span>
      <div class="flex flex-col">
        <NuxtLink
          to="/2/referral"
          class="
              text-[0.8125rem]
              text-gray-700
              before:absolute
              before:inset-0
              focus:outline-none
              "
          @click="$posthog.capture('sidebar_referral_link_clicked')"
        >
          {{ $t('sidebar.referral.title') }}
        </NuxtLink>
        <span class="text-xs text-gray-400">
          {{ $t('sidebar.referral.text', {
            amount: formatCurrency(rewardAmount, { notation: 'compact' }),
          }) }}
        </span>
      </div>
    </div>

    <TrialAlert
      v-if="hasTrialSubscription"
      class="my-2.5"
    />

    <GracePeriodCard
      v-if="!!workspace?.attributes.retention_starts_at && !!workspace?.attributes.grace_starts_at"
      :grace-starts-at="workspace?.attributes.grace_starts_at"
      :grace-ends-at="workspace?.attributes.retention_starts_at"
      class="m-2.5"
    />
  </aside>
</template>
